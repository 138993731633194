<template>
  <div>
    <Breadcrumbs page_title="community" :items="breadcrumbs" />
    <v-container fluid>
      <v-row class="mx-2">
        <v-col cols="12" xs="12" sm="12" md="3" lg="2" class="lg5-custom">
          <v-card
            style="border-radius: 16px;"
            height="200"
            @click="$router.push('/home/community/expert-consultant')"
          >
            <v-card-text justify="center" align="center" style="height:100%">
              <div class="imgtext"></div>
              <v-img
                src="@/assets/Group 9041.png"
                width="52"
                class="communityimg"
                ></v-img>
              <p class="text-center mt-5" style="font-size:18px;color:#424242">{{$t('expertcons')}}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2" class="lg5-custom">
          <v-card
            style="border-radius: 16px;"
            height="200"
            @click="$router.push('/home/community/frequently-asked-question')"
          >
            <v-card-text justify="center" align="center">
              <div class="imgtext"></div>
              <v-img
                src="@/assets/Iconly-Bold-Chat.png"
                width="52"
                class="communityimg"
              ></v-img>
              <p class="text-center mt-5" style="font-size:18px;color:#424242">{{$t('frequentlyask')}}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped lang="css">
  .imgtext{
    height: 130px;
    background-color: #A6CC39;
    opacity: 0.1;
    border-radius: 8px;
    position: relative;
    z-index: 0!important;
  }
  .communityimg{
    position: absolute;
    top: 40%;
    left: 48%;
    transform: translate(-50%, -48%);
  }
  .v-image__image{
    z-index: 10!important;
  }
</style>

<style>
  @media (min-width: 1264px) and (max-width: 1903px) {
    .lg5-custom {
        width: 25%;
        max-width: 25%;
        flex-basis: 25%;
    }
}
</style>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        // image: 'house.png',
        sidebar_tilte: "home"
      },
      {
        text: "community"
      },
    ],
  }),
};
</script>